@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;500;700&display=swap");

html {
  background-color: black;
}

.sparklelink {
  transition: all 0.25s ease-in 0s;
  cursor: pointer;
}

.sparklelink:hover {
  transform: scale(1.25);
}

.proj:hover {
  transform: translateX(1rem);
}

.proj:hover > .thumb {
  opacity: 1;
}

.thumb {
  transition: all 0.25s linear 0s;
}

.purpleSparklePt {
  list-style-image: url(../public/cursors/pt1purple.gif);
}
